import React, { ReactNode } from 'react'

interface Props {
  direction?: 'up' | 'down'
  className?: string
  children?: ReactNode
}

const HelperArrow: React.FC<Props> = ({ direction, className, children }) => {
  let rotation = null
  if (direction === 'up') {
    rotation = 'rotate-180 scale-x-[-1]'
  }

  return (
    <div className={`${className} m-auto text-center`}>
      {!rotation && <>{children}</>}
      <svg
        className={`${rotation} mx-auto h-1/2 w-1/2`}
        width="62"
        height="62"
        viewBox="0 0 62 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M54.1989 53.6021C53.6989 20.2041 38.1989 12.1021 31.3979 10.2041C29.6987 9.70406 27.9995 9.40327 26.3979 9.30561C23.7963 5.30561 17.2963 0.204016 0.596924 0.704016L0.698484 2.70402C12.0965 2.30558 20.1985 4.70402 23.8975 9.20402C17.4991 9.40324 12.1985 12.204 10.0965 15.704C8.39728 18.5048 8.79568 21.5048 11.0965 24.1024C14.1981 27.5008 18.2957 28.3016 21.9945 26.204C26.2953 23.8056 29.5961 17.3056 27.3929 11.403C36.4945 12.5046 51.4949 20.5046 52.0919 53.102L46.9981 47.8012L45.5997 49.1996L53.4005 57.1996L61.4005 49.3988L59.9982 48.0004L54.1989 53.6021ZM21.1009 24.5001C19.4017 25.3985 16.1009 26.5001 12.6009 22.8009C10.8001 20.9025 10.6009 18.8009 11.8001 16.8009C13.6985 13.6993 18.8001 11.3009 24.8001 11.3009H25.1986C25.3978 11.8009 25.597 12.3009 25.8001 12.9025C27.1985 17.8009 24.4993 22.6017 21.1009 24.5005L21.1009 24.5001Z"
          fill="#6B7280"
        />
      </svg>
      {rotation && <>{children}</>}
    </div>
  )
}

export default HelperArrow
